
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class QRStatus extends Vue {
  @State(({ receptionistState }) => receptionistState.isValid)
  isValid!: boolean
  @State(({ receptionistState }) => receptionistState.hasPermission)
  hasPermission!: boolean
}
